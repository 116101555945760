import axios from '@/libs/axios'
export default class Branches {

    /**
     * Function to branches
     * @return Promise
     */
    index(idEmpresa) {
        return axios.get(`/Sucursales/GetAll/${idEmpresa}`);
    }
}
