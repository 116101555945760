<template>    
    <div>
        <ul>
            <li v-for="menu in items" :key="menu.permission">
                <b-form-checkbox :disabled="role == ''" v-model="menu.enabled" :class="{ 'menu-parent': menu.children || menu.specials }">
                    <feather-icon :icon="menu.icon" class="menu-icon" /> {{ menu.title }}
                </b-form-checkbox>

                <!-- Recursivamente mostrar hijos si existen -->
                <transition name="fade">
                    <menu-tree v-if="menu.children" :items="menu.children" :role="role" />
                </transition>
                <transition name="fade">
                    <menu-tree v-if="menu.specials" :items="menu.specials" :role="role" />
                </transition>
            </li>
        </ul>
    </div>    
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { required } from 'vee-validate/dist/rules';

export default {
    name: "MenuTree",

    components: {
        BFormCheckbox,
        FeatherIcon,
        VuePerfectScrollbar,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
            required: true
        },
        role: {
            type: String,
            default: '',
            required: true
        }
    },
    setup() {

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        return {
            perfectScrollbarSettings
        }
    },
}
</script>

<style scoped>

.scrollable-container {
  max-height: 400px; /* Ajusta según tus necesidades */
  overflow: auto;
}

ul {
    padding-left: 0;
    margin: 0;
}

ul li {
    list-style: none;
    position: relative;
    padding-left: 0;
    margin-bottom: 10px;
}

/* Negritas para los menús padres */
.menu-parent {
    font-weight: bold;
    font-size: 16px;
}

.menu-icon {
    margin-right: 8px;
    /* Espacio entre el ícono y el texto */
}

/* Submenús (hijos) más pequeños y con indentación */
ul ul {
    margin-left: 20px;
    font-size: 14px;
}

/* Transición suave para los submenús */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* Checkbox customizado */
.b-form-checkbox {
    display: flex;
    align-items: center;
}

/* Estilo normal de los menús padres */
.menu-parent {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    /* Cambiar el cursor al pasar sobre el menú */
    transition: background-color 0.3s ease, color 0.3s ease;
    /* Transición suave */
}

/* Cambia el color y el fondo cuando se hace hover */
.menu-parent:hover {
    background-color: #f0f0f0;
    /* Fondo más claro */
    color: #007bff;
    /* Cambia el color del texto */
}

/* Hover para los íconos también */
.menu-icon:hover {
    color: #007bff;
    /* Cambia el color del ícono */
}

/* Añade una animación al ícono cuando se hace hover */
.menu-icon {
    margin-right: 8px;
    transition: transform 0.3s ease, color 0.3s ease;
    /* Transición suave */
}

/* Al hacer hover, el ícono gira ligeramente */
.menu-icon:hover {
    transform: rotate(15deg);
    color: #007bff;
    /* Cambia el color del ícono */
}
</style>